import { all } from 'ramda';
import { put } from 'redux-saga/effects';
import { ICredentialsResponse, ICredentialsRequest, DatosCredentials } from '../../../models/ICredentials';
import { DatosFindTokenLoginClient } from '../../../models/IFindTokenLoginClientResponse';
import { DatosFindValidStatus } from '../../../models/IFindValidStatusResponse';
import { DatosWebHookStatus, IWebHookStatusRequest } from '../../../models/IWebHookStatusRequest';
import * as type from '../../../System/types';
import * as actions from '../../../System/types';

//#region FIND TOKEN LOGIN
export function requestFindTokenLoginClient(data: string): actions.requestFindTokenLoginClientAction {
    return {
        type: type.FINDTOKENLOGINCLIENT_REQUEST,
        inProgress: true,
        data
    };
}

export function onFindTokenLoginClientResponse(
    data: DatosFindTokenLoginClient,
    message: string
): actions.onFindTokenLoginClientResponseAction {
    return {
        type: type.FINDTOKENLOGINCLIENT_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onFindTokenLoginClientFailed(
    message: string
): actions.getFindTokenLoginClientFailedAction {
    return {
        type: type.FINDTOKENLOGINCLIENT_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region FIND VALID STATUS
export function requestFindValidStatus(data: string): actions.requestFindValidStatusAction {
    return {
        type: type.FINDVALIDSTATUS_REQUEST,
        inProgress: true,
        data
    };
}

export function onFindValidStatusResponse(
    data: DatosFindValidStatus,
    message: string
): actions.onFindValidStatusResponseAction {
    return {
        type: type.FINDVALIDSTATUS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onFindValidStatusFailed(
    message: string
): actions.getFindValidStatusFailedAction {
    return {
        type: type.FINDVALIDSTATUS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region CREDENTIALS
export function requestCredentials(data: ICredentialsRequest): actions.requestCredentialsAction {
    return {
        type: type.CREDENTIALS_REQUEST,
        inProgress: true,
        message: '',
        data
    };
}

export function onCredentialsResponse(
    data: DatosCredentials,
    message: string
): actions.onCredentialsResponseAction {
    return {
        type: type.CREDENTIALS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onCredentialsFailed(
    message: string
): actions.getCredentialsFailedAction {
    return {
        type: type.CREDENTIALS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region WEBHOOK STATUS RESPONSE
export function requestWebHookStatus(data: IWebHookStatusRequest): actions.requestWebHookStatusAction {
    return {
        type: type.WEBHOOKSTATUS_REQUEST,
        inProgress: true,
        data
    };
}

export function onWebHookStatusResponse(
    data: DatosWebHookStatus,
    message: string
): actions.onWebHookStatusResponseAction {
    return {
        type: type.WEBHOOKSTATUS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onWebHookStatusFailed(
    message: string
): actions.getWebHookStatusFailedAction {
    return {
        type: type.WEBHOOKSTATUS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion