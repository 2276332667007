import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Image from 'mui-image';
import { useDispatch, useSelector } from "react-redux";
import * as credentialsActions from './Redux/actions';
import { ICredentialsRequest } from '../../models/ICredentials';
import * as R from 'ramda';
import { DatosWebHookStatus, IWebHookStatusRequest, IWebHookStatusResponse } from '../../models/IWebHookStatusRequest';
import { CircularProgress } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { getStatusWebHook } from '../../utils';
import $ from 'jquery';
import { DatosFindValidStatus } from '../../models/IFindValidStatusResponse';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { DatosFindTokenLoginClient } from '../../models/IFindTokenLoginClientResponse';
import BadParameters from '../NotFound/BadParameters';
import { env } from '../../api/env';

export default function Credentials() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [error, setError] = useState("");
    const idTokenLogin = (R.isNil(urlParams.get('id')) ? '' : urlParams.get('id') + '');
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idTokenLoginValid = regexExp.test(idTokenLogin);

    const inProgress = useSelector((state: any) => state.credentials.inProgress);
    let datosCredentials: any = useSelector((state: any) => state.credentials.data);

    const datosFindTokenLoginClient: DatosFindTokenLoginClient = useSelector((state: any) => state.findTokenLoginClient.data);
    const [rfc, setRFC] = useState('');
    const [password, setPassword] = useState('');
    const [autorizaConsulta, setAutorizaConsulta] = useState(false);
    const type = 'ciec';

    let responseSend: IWebHookStatusRequest = {
        rfc: '',
        numeroRegistros: null
    }

    let dataSend: ICredentialsRequest = {
        type: type,
        rfc: $('#rfc').val() + '',
        password: password,
        // autorizaConsulta
    }

    const dispatch = useDispatch();
    const findTokenLoginClientRequest = useCallback(() => dispatch(credentialsActions.requestFindTokenLoginClient(idTokenLogin)), [dispatch, idTokenLogin]);
    const credentialsRequest = useCallback(() => dispatch(credentialsActions.requestCredentials(dataSend)), [dispatch, dataSend]);
    const statusWebHookRequest = useCallback(() => dispatch(credentialsActions.requestWebHookStatus(responseSend)), [dispatch, responseSend]);

    React.useEffect(() => {
        if (idTokenLoginValid) {
            findTokenLoginClientRequest();
        }
    }, []);

    React.useEffect(() => {
        $('#rfc').val(localStorage.getItem('rfcToCiec') + '');
    }, [datosFindTokenLoginClient?.existe == true]);

    const regexExpRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let formErrors = <div><Typography align="center" color='error.main'>{error}</Typography></div>;

    const submitCIEC = (event: any) => {
        event.preventDefault();
        setError("");
        setTimeout(() => {
            let rfcValid = regexExp.test($('#rfc').val() + '');
            if (!rfcValid) {
                setError("Ingrese un RFC válido.");
            }
            else if (password.length < 8) {
                setError("Ingrese una clave CIEC válida.")
            }
            else {
                credentialsRequest();
            }
        }, 1000);
    };

    const datosFindValidStatus: DatosFindValidStatus = useSelector((state: any) => state.findValidStatus.data);
    const insProgressFindValidStatus: DatosWebHookStatus = useSelector((state: any) => state.findValidStatus.inProgress);
    const datosWebHookStatus: DatosWebHookStatus = useSelector((state: any) => state.webHookStatus.data);
    const inProgressWebHookStatus: DatosWebHookStatus = useSelector((state: any) => state.webHookStatus.inProgress);

    let result = <div><Typography></Typography></div>;
    let fechaActualizacion = '';
    if (!R.isNil(datosFindValidStatus)) {
        $('#boxInProgressCIEC').css('display', 'none');
        if (datosFindValidStatus.valid) {
            fechaActualizacion = datosFindValidStatus.updatedAtString;
            $('#boxFindStatusValid').css('display', 'block');
        }
        else {
            $('#boxFormGetCIEC').css('display', 'block');
        }
    }
    if (insProgressFindValidStatus) {
        $('#boxFormGetCIEC').css('display', 'none');
        $('#boxInProgressCIEC').css('display', 'block');
    }

    if (!R.isNil(datosCredentials) && !inProgress) {
        let timeOut: any = null;
        if (R.isNil(datosWebHookStatus)) {
            $("#sendButton").css("display", "none");
            result =
                <><Typography align="center"><DoneOutlinedIcon sx={{ color: 'success.main' }} /></Typography>
                    <Typography align="center" color="success.main">Enviado.</Typography></>;
            responseSend.rfc = datosCredentials.rfc;
            timeOut = setTimeout(() => {
                statusWebHookRequest();
            }, 1000);
        }
        else {
            if (datosWebHookStatus.actualizado) {
                datosCredentials = null;
                result = <>{getStatusWebHook(datosWebHookStatus.status)}</>;
                if (datosWebHookStatus.status == 'valid') {
                    setTimeout(() => {
                        window.location.replace(env.URL_CLIENTS + '/AccountsSignInUi?token=' + localStorage.getItem('token'));
                    }, 5000);
                }
            }
            else {
                console.log("Numero de registros: " + datosWebHookStatus.numeroRegistros);
                $("#sendButton").css("display", "none");
                result =
                    <><Typography align="center"><CircularProgress /></Typography>
                        <Typography align="center">Comprobando...</Typography></>;
                if (!inProgressWebHookStatus) {
                    responseSend = {
                        rfc: datosCredentials.rfc,
                        numeroRegistros: datosWebHookStatus.numeroRegistros
                    }
                    timeOut = setTimeout(() => {
                        statusWebHookRequest();
                    }, 5000);
                }
            }
        }
    }

    if (!idTokenLoginValid) {
        return (<BadParameters />);
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '50vh' }}>
            <Grid item xs={3}>
                <Grid container component="main">
                    <Container maxWidth='xs' style={{ backgroundColor: '#f2f6fc', border: '1px solid black', marginTop: '33px', marginBottom: '33px', opacity: "0.9" }}>
                        <Box id="boxFormGetCIEC"
                            sx={{
                                m: 2,
                                display: 'none',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                            <Typography align="center" component="h1" variant="h6" color="#0D79BF" sx={{ m: 4 }}>
                                Ingrese sus credenciales
                            </Typography>
                            <Box component="form" onSubmit={submitCIEC} sx={{ mt: 0 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="rfc"
                                    label="RFC"
                                    autoComplete="off"
                                    onChange={e => setRFC(e.target.value)} />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="ciec"
                                    label="CIEC"
                                    type="password"
                                    id="ciec"
                                    autoComplete="new-password"
                                    onChange={e => setPassword(e.target.value)} />
                                <FormControlLabel
                                    control={<Checkbox id="autorizaConsulta" value="autorizaConsulta" onChange={e => setAutorizaConsulta(e.target.checked)} />}
                                    label="Autorizo consulta a Buró de Crédito" />
                                <Button
                                    id="sendButton"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2 }}
                                    disabled={inProgress} >
                                    Enviar
                                </Button >
                                <Typography align="center" sx={{ fontSize: "14px", mt: 2 }}>
                                    Al seleccionar "Enviar", usted acepta nuestros <Link>Términos de Uso</Link> y <Link>Aviso de Privacidad</Link>.
                                </Typography>
                                <Grid sx={{ m: 3 }} item xs={12} sm={12}>
                                    {formErrors}
                                </Grid>
                                <Grid sx={{ m: 3 }} item xs={12} sm={12}>
                                    {result}
                                </Grid>
                            </Box>
                        </Box>
                        <Box component="form" id="boxInProgressCIEC" sx={{ m: 4 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={12}>
                                    <Typography align="center"><CircularProgress /></Typography>
                                    <Typography align="center">Buscando credenciales válidas...</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box component="form" id="boxFindStatusValid" sx={{ m: 4, display: 'none' }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={12}>
                                    <Typography align="center"><TaskAltOutlinedIcon sx={{ color: 'success.main' }} /></Typography>
                                    <Typography align="center" color='success.main'>Ya contamos con sus credenciales, con fecha de registro el día {fechaActualizacion}.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}