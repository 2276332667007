import * as types from '../../../System/types'

export interface SetPasswordState {
    email: string,
    token: string,
    errors?: string[],
    inProgress: boolean,
    message?: string | null
}

const setPasswordInitialState: SetPasswordState = {
    email: '',
    token: '',
    errors: [],
    inProgress: false
};

export default function setPasswordReducer(
    state: SetPasswordState = setPasswordInitialState,
    action: types.RootAction
): SetPasswordState {
    switch (action.type) {
        case types.SETPASSWORD_REQUEST:
            return {
                ...state,
                ...action.data,
                inProgress: true,
                message: '',
                errors: []
            }
        case types.SETPASSWORD_RESPONSE:
            return {
                ...state,
                ...action.data,
                message: action.message,
                errors: action.errors,
                inProgress: false 
            }
        case types.SETPASSWORD_FAILED:
            return {
                ...state,
                message: action.message,
                errors: action.errors, 
                inProgress: false
            }
        default:
            return state;
    }
}