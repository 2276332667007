import { IAuthenticate } from "../models/IAuthenticate";
import { ILogin } from "../models/ILogin";
import { IResponse } from "../models/IResponse";
import { ISetPassword } from "../models/ISetPassword";
import { ICredentialsResponse, ICredentialsRequest, DatosCredentials } from "../models/ICredentials";
import { DatosFindValidStatus } from "../models/IFindValidStatusResponse";
import { DatosWebHookStatus, IWebHookStatusRequest } from "../models/IWebHookStatusRequest";
import { DatosFindTokenLoginClient } from "../models/IFindTokenLoginClientResponse";

//#region LOADER
export const ENABLE_LOADER = 'ENABLE_LOADER';
export interface getEnableLoaderAction {
    type: typeof ENABLE_LOADER;
    response: IResponse;
    totalLoaded: number;
}
export const DISABLE_LOADER = 'DISABLE_LOADER';
export interface getDisableLoaderAction {
    type: typeof DISABLE_LOADER;
    response: IResponse;
    totalLoaded: number;
}
//#endregion

//#region LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export interface requestLoginAction {
    type: typeof LOGIN_REQUEST;
    data: ILogin;
    inProgress: boolean;
}
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export interface onLoginResponseAction {
    type: typeof LOGIN_RESPONSE;
    data: IAuthenticate;
    message: string;
    isLoggedIn: boolean;
    inProgress: boolean;
}
export const LOGIN_FAILED = 'LOGIN_FAILED';
export interface getLoginFailedAction {
    type: typeof LOGIN_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region SETPASSWORD
export const SETPASSWORD_REQUEST = 'SETPASSWORD_REQUEST';
export interface requestSetPasswordAction {
    type: typeof SETPASSWORD_REQUEST;
    data: ISetPassword;
    inProgress: boolean;
}
export const SETPASSWORD_RESPONSE = 'SETPASSWORD_RESPONSE';
export interface onSetPasswordResponseAction {
    type: typeof SETPASSWORD_RESPONSE;
    data: any;
    message: string;
    errors: Array<string>,
    inProgress: boolean;
}
export const SETPASSWORD_FAILED = 'SETPASSWORD_FAILED';
export interface getSetPasswordFailedAction {
    type: typeof SETPASSWORD_FAILED;
    errors: Array<string>;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region FORGOTPASSWORD
export const FORGOTPASSWORD_REQUEST = 'FORGOTPASSWORD_REQUEST';
export interface requestForgotPasswordAction {
    type: typeof FORGOTPASSWORD_REQUEST;
    email: string;
    inProgress: boolean;
}
export const FORGOTPASSWORD_RESPONSE = 'FORGOTPASSWORD_RESPONSE';
export interface onForgotPasswordResponseAction {
    type: typeof FORGOTPASSWORD_RESPONSE;
    data: any;
    message: string;
    inProgress: boolean;
}
export const FORGOTPASSWORD_FAILED = 'FORGOTPASSWORD_FAILED';
export interface getForgotPasswordFailedAction {
    type: typeof FORGOTPASSWORD_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region FIND TOKEN LOGIN
export const FINDTOKENLOGINCLIENT_REQUEST = 'FINDTOKENLOGINCLIENT_REQUEST';
export interface requestFindTokenLoginClientAction {
    type: typeof FINDTOKENLOGINCLIENT_REQUEST;
    data: string;
    inProgress: boolean;
}
export const FINDTOKENLOGINCLIENT_RESPONSE = 'FINDTOKENLOGINCLIENT_RESPONSE';
export interface onFindTokenLoginClientResponseAction {
    type: typeof FINDTOKENLOGINCLIENT_RESPONSE;
    data: DatosFindTokenLoginClient;
    message: string;
    inProgress: boolean;
}
export const FINDTOKENLOGINCLIENT_FAILED = 'FINDTOKENLOGINCLIENT_FAILED';
export interface getFindTokenLoginClientFailedAction {
    type: typeof FINDTOKENLOGINCLIENT_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region FIND VALID STATUS
export const FINDVALIDSTATUS_REQUEST = 'FINDVALIDSTATUS_REQUEST';
export interface requestFindValidStatusAction {
    type: typeof FINDVALIDSTATUS_REQUEST;
    data: string;
    inProgress: boolean;
}
export const FINDVALIDSTATUS_RESPONSE = 'FINDVALIDSTATUS_RESPONSE';
export interface onFindValidStatusResponseAction {
    type: typeof FINDVALIDSTATUS_RESPONSE;
    data: DatosFindValidStatus;
    message: string;
    inProgress: boolean;
}
export const FINDVALIDSTATUS_FAILED = 'FINDVALIDSTATUS_FAILED';
export interface getFindValidStatusFailedAction {
    type: typeof FINDVALIDSTATUS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region CREDENTIALS
export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export interface requestCredentialsAction {
    type: typeof CREDENTIALS_REQUEST;
    data: ICredentialsRequest;
    message: string,
    inProgress: boolean;
}
export const CREDENTIALS_RESPONSE = 'CREDENTIALS_RESPONSE';
export interface onCredentialsResponseAction {
    type: typeof CREDENTIALS_RESPONSE;
    data: DatosCredentials;
    message: string;
    inProgress: boolean;
}
export const CREDENTIALS_FAILED = 'CREDENTIALS_FAILED';
export interface getCredentialsFailedAction {
    type: typeof CREDENTIALS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region WEBHOOK STATUS
export const WEBHOOKSTATUS_REQUEST = 'WEBHOOKSTATUS_REQUEST';
export interface requestWebHookStatusAction {
    type: typeof WEBHOOKSTATUS_REQUEST;
    data: IWebHookStatusRequest;
    inProgress: boolean;
}
export const WEBHOOKSTATUS_RESPONSE = 'WEBHOOKSTATUS_RESPONSE';
export interface onWebHookStatusResponseAction {
    type: typeof WEBHOOKSTATUS_RESPONSE;
    data: DatosWebHookStatus;
    message: string;
    inProgress: boolean;
}
export const WEBHOOKSTATUS_FAILED = 'WEBHOOKSTATUS_FAILED';
export interface getWebHookStatusFailedAction {
    type: typeof WEBHOOKSTATUS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

export type RootAction =
    | getEnableLoaderAction
    | getDisableLoaderAction

    | requestLoginAction
    | onLoginResponseAction
    | getLoginFailedAction

    | requestSetPasswordAction
    | onSetPasswordResponseAction
    | getSetPasswordFailedAction

    | requestForgotPasswordAction
    | onForgotPasswordResponseAction
    | getForgotPasswordFailedAction

    | requestFindTokenLoginClientAction
    | onFindTokenLoginClientResponseAction
    | getFindTokenLoginClientFailedAction

    | requestFindValidStatusAction
    | onFindValidStatusResponseAction
    | getFindValidStatusFailedAction

    | requestCredentialsAction
    | onCredentialsResponseAction
    | getCredentialsFailedAction

    | requestWebHookStatusAction
    | onWebHookStatusResponseAction
    | getWebHookStatusFailedAction