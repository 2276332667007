import RemoteData from "./RemoteData";
import API from "./ApiConstants";
import { ILogin } from "../models/ILogin";
import { env } from "./env";
import { ISetPassword } from "../models/ISetPassword";
import { ICredentialsRequest } from "../models/ICredentials";
import { IWebHookStatusRequest } from "../models/IWebHookStatusRequest";

export function LoginUser(data: ILogin) {     
    return RemoteData.post(API.LOGIN, {
        route: env.ROUTE_CONFIRM_EMAIL,
        origin: env.BASE_URL_SECURE
    }, {
        email: data.userName,
        password: data.password,
        remember: data.remember
    })
};

export function SetPasswordUser(data: ISetPassword) {
    return RemoteData.post(API.RESSET_PASSWORD, null,
        {
            email: data.email,
            token: data.token,
            password: data.password,
            confirmPassword: data.confirmPassword,
            aplicacion: data.aplicacion
        })
};

export function ForgotPasswordUser(email: string) {
    return RemoteData.post(API.FORGOT_PASSWORD, {
        origin: env.BASE_URL_SECURE
    }, {
        email: email
    })
};

export function ConfirmEmail(userId: string, code: string) {
    let urlConfirmEmail = API.CONFIRM_EMAIL.replace("{userId}", userId).replace("{code}", code);
    return RemoteData.get(urlConfirmEmail);
};

export function FindTokenLoginClient(data: string) {
    return RemoteData.get(API.CONSULTA_TOKENLOGIN,
        {
            IdUserToken: data
        })
};

export function FindValidStatus(data: string) {
    return RemoteData.get(API.CONSULTA_FINDVALIDSTATUS,
        {
            RFC: data
        })
};

export function CredentialsUser(data: ICredentialsRequest) {
    return RemoteData.post(API.CONSULTA_CIEC,
        {
            Type: data.type,
            RFC: data.rfc,
            Password: data.password
        })
};

export function WebHookStatus(data: IWebHookStatusRequest) {
    return RemoteData.get(API.CONSULTA_WEBHOOKSTATUS,
        {
            RFC: data.rfc,
            NumeroRegistros: data.numeroRegistros
        })
};