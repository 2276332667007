import { combineReducers } from "redux";
import rootReducer from '../System/reducers';
import signInReducer from '../screens/SignIn/Redux/reducers';
import setPasswordReducer from "../screens/SetPassword/Redux/reducers";
import forgotPasswordReducer from "../screens/ForgotPassword/Redux/reducers";
import { credentialsReducer, findTokenLoginClientReducer, findValidStatusReducer, webHookStatusReducer } from "../screens/Credentials/Redux/reducers";

const globalReducer = combineReducers({
  root: rootReducer,
  signIn: signInReducer,
  setPassword: setPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  credentials: credentialsReducer,
  webHookStatus: webHookStatusReducer,
  findValidStatus: findValidStatusReducer,
  findTokenLoginClient: findTokenLoginClientReducer
});

export type AppState = ReturnType<typeof globalReducer>;

export default globalReducer;