import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Button, Container, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { getApplicationName, getRedirectLink } from '../../utils';
import AppsIcon from '@mui/icons-material/Apps';
import theme from '../../theme';

export default function Applications() {
    const inProgress = useSelector((state: any) => state.forgotPassword.inProgress);
    const applications = useSelector((state: any) => state.signIn.applications);
    const token = useSelector((state: any) => state.signIn.token);
    const nombreUsuario = useSelector((state: any) => state.signIn.nombreUsuario);
    const email = useSelector((state: any) => state.signIn.email);
    let apps = '';
    if (!R.isNil(applications)) {
        apps = applications.map((app: number) =>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                        <AppsIcon />
                    </Avatar>
                }
                action={
                    <CardActions>
                        <Button target='_blank' disabled={inProgress} variant="contained" href={getRedirectLink(app, token)} sx={{ backgroundColor: 'primary.main' }}>
                            ACCEDER</Button>
                    </CardActions>
                }
                key={app}
                title={getApplicationName(app)} />)
    }
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{ bgcolor: 'secondary.main', width: 100, height: 100 }}>
                </Avatar>
                <Typography component="h1" variant="h4" sx={{ mt: 3, mb: 3 }}>
                    PCH Capital
                </Typography>
                <Card sx={{ minWidth: 480, maxWidth: 1024 }}>
                    <Typography component="h1" variant="h6" align='center' sx={{ m: 3 }} display="block">
                        {nombreUsuario}
                    </Typography>
                    <Typography component="h1" variant="body2" align='center' sx={{ m: 3 }} display="block">
                        {email}
                    </Typography>
                    <hr />
                    <Typography component="h1" variant="h6" sx={{ m: 3 }} >
                        Aplicaciones:
                    </Typography>
                    {apps}
                </Card>
            </Box>
        </Grid>
    );
}