import { IAuthenticate } from '../../../models/IAuthenticate';
import { ILogin } from '../../../models/ILogin';
import * as type from '../../../System/types';
import * as actions from '../../../System/types';

export function requestLogin(data: ILogin): actions.requestLoginAction {
    return {
        type: type.LOGIN_REQUEST,
        inProgress: true,
        data
    };
}

export function onLoginResponse(
    data: IAuthenticate,
    message: string,
    isLoggedIn: boolean
): actions.onLoginResponseAction {
    return {
        type: type.LOGIN_RESPONSE,
        data,
        message,
        isLoggedIn,
        inProgress: false
    };
}