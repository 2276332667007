import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../System/types';
import * as R from 'ramda';
import { LoginUser } from '../../../api/Client';
import { IResponse } from '../../../models/IResponse';
import { GetOToken } from '../../../utils';

export function* signInRequestAsync(action: types.requestLoginAction) {
    const response: IResponse = yield call(LoginUser, action.data);
    try {
        const {
            data,
            status,
        } = response;
        
        if (!R.isNil(data)) {
            let partir = data.jwToken.split('.');
            if(partir.length == 3){
                let token = partir[1];
                let oIdentity = GetOToken(token);                
                data.applications = oIdentity.app.split(",").map(Number);
                data.id = oIdentity.uid;
                data.email = oIdentity.email;
                data.jwToken = data.jwToken;
                data.token =  token;
                
                // data.userName = oIdentity.userName;
                data.nombreUsuario = oIdentity.nombreUsuario;
                
                // data.remember = oIdentity.remember;
                data.isVerified = oIdentity.isVerified;
            }
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* signInSaga() {
    yield all([
        takeEvery(types.LOGIN_REQUEST, signInRequestAsync)
    ])
}