import { eApplications } from "../System/eApplications";
import { env } from "../api/env/index";
import { Typography } from "@mui/material";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const GetResponseErrors = (response: any) => {
    let errors = new Array;
    let rootProperties = Object.keys(response);
    if (rootProperties.find((key) => key == 'errors') != undefined) {
        if (response.errors != undefined && !Array.isArray(response.errors)) {
            for (const nameElement in response.errors) {
                response.errors[nameElement].map((msg: string, i: number) => errors.push(msg))
            }
        }
        else if (Array.isArray(response.errors)) {
            response.errors.map((msg: string, i: number) => errors.push(msg))
        }
    }
    return errors;
}

export function getApplicationName(app: number): string {
    let name = 'Sin aplicaciones';
    switch (app) {
        case eApplications.PCH:
            name = 'PCH Capital'
            break;
        case eApplications.PCHServiciosFinancieros:
            name = 'PCH Power Finance'
            break;
        case eApplications.PCHInversionista:
            name = 'Inversionistas'
            break;
        case eApplications.PCHCliente:
            name = 'Clientes'
            break;
    }
    return name;
}

export function getRedirectLink(app: number, token: string) {
    let url = '';
    switch (app) {
        case eApplications.PCH:
            break;
        case eApplications.PCHServiciosFinancieros:
            url = env.URL_POWER_FINANCE + '/InicioSesion/IniciarSesion?token=' + token;
            break;
        case eApplications.PCHInversionista:
            url = 'Inversionista'
            break;
        case eApplications.PCHCliente:
            url = env.URL_CLIENTS + '/AccountsSignInUi?token=' + token;
            break;
    }
    return url;
}

export function getStatusWebHook(status: string) {
    switch (status) {
        case 'valid':
            return (<><Typography align="center"><TaskAltOutlinedIcon sx={{ color: 'success.main' }} /></Typography>
                <Typography align="center" color='success.main'>Credenciales válidas.</Typography>
                <Typography align="center" color='success.main'>En breve, nuestro sistema se encargará de recabar la información para su trámite.</Typography></>);
        case 'invalid':
            return (<><Typography align="center"><ErrorOutlineOutlinedIcon sx={{ color: 'error.main' }} /></Typography>
                <Typography align="center" color='error.main'>Credenciales inválidas.</Typography>
                <Typography align="center" color='error.main'>Vuelva a intentarlo.</Typography></>);
    }
}

export function GetOToken(token: string){
    let oToken : any = {};
    try {
        oToken = JSON.parse(decodeURIComponent(escape(atob(token))));
    } catch (error:any) {
        
    }
    return oToken;
}