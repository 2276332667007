import { ISetPassword } from '../../../models/ISetPassword';
import * as type from '../../../System/types';
import * as actions from '../../../System/types';

export function requestForgotPassword(email: string): actions.requestForgotPasswordAction {
    return {
        type: type.FORGOTPASSWORD_REQUEST,
        inProgress: true,
        email
    };
}

export function onForgotPasswordResponse(
    data: any,
    message: string,
): actions.onForgotPasswordResponseAction {
    return {
        type: type.FORGOTPASSWORD_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onForgotPasswordFailed(
    message: string
): actions.getForgotPasswordFailedAction {
    return {
        type: type.FORGOTPASSWORD_FAILED,
        message,
        inProgress: false
    };
}