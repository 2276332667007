import * as types from './types';

export interface rootState {
    totalLoaded: number,
    typeUser: string,
    navigation: {
        previous: any,
        current: any,
    },
    isLoading: boolean,
    statusBarVisibility: boolean,
    statusBarStyle: string,
    statusBarTransition: string,
}

const rootInitialState: rootState = {
    totalLoaded: 0,
    typeUser: '',
    navigation: {
        previous: null,
        current: null,
    },
    isLoading:false,
    statusBarVisibility: true,
    statusBarStyle: '',
    statusBarTransition: '',
};

export function rootReducer(
    state: rootState = rootInitialState,
    action: types.RootAction
): rootState {
    switch (action.type) {
        case types.ENABLE_LOADER:
            return {
                ...state,
                isLoading: true,
                totalLoaded: state.totalLoaded + action.totalLoaded,
            };
        case types.DISABLE_LOADER:
            return {
                ...state,
                isLoading: (state.totalLoaded > 0 ? state.totalLoaded - action.totalLoaded : 0) !== 0,
                totalLoaded: state.totalLoaded > 0 ? state.totalLoaded - action.totalLoaded : 0,
            };
        default:
            return state;
    }
}

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
