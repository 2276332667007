import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Image from 'mui-image';
import logo from '../../assets/pchcapital_cuadrado.svg';
import { useDispatch, useSelector } from "react-redux";
import * as loginActions from './Redux/actions';
import { isEmpty } from 'ramda';
import { ILogin } from '../../models/ILogin';
import Alert from '@mui/material/Alert';
import Copyright from '../../components/common/CopyRight';
import { Link, redirect } from 'react-router-dom';

export default function SignIn() {
    const inProgress = useSelector((state: any) => state.signIn.inProgress);
    const message = useSelector((state: any) => state.signIn.message);
    const isLoggedIn = useSelector((state: any) => state.signIn.isLoggedIn);
    const [usingEmail, setUsingEmail] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('Email inválido — La dirección de correo electrónico no es válida!');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);

    let dataSend: ILogin = {
        userName,
        password,
        remember
    }

    const dispatch = useDispatch();
    const loginRequest = useCallback(() => dispatch(loginActions.requestLogin(dataSend)), [dispatch, dataSend]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setShowError(false);
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!usingEmail) {
            loginRequest();
            return;
        }
        if (reg.test(String(userName).toLowerCase())) {
            loginRequest();
        } else {
            setShowError(true);
            setError('El correo proporcionado no es valido!')
            if (isEmpty(userName)) {
                setUserName('');
            }
        }
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xs={3}>
                <Grid container component="main">
                    <Container style={{ backgroundColor: '#f2f6fc', border: '1px solid black', opacity: "0.9" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                            <Image src={logo} height="60%" width="60%" style={{ margin: '50px 0 50px 0' }} />
                            <Typography component="h1" variant="h5" color="#C96B12">
                                Bienvenido
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Usuario"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={e => setUserName(e.target.value)} />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={e => setPassword(e.target.value)} />
                                <FormControlLabel
                                    control={<Checkbox id="remember" checked={remember} value="remember" onChange={e => setRemember(e.target.checked)} color="primary" />}
                                    label="Recuérdame" />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1 }}
                                    disabled={inProgress} >
                                    Ingresar
                                </Button >
                                {showError && <Alert severity="error">{error}</Alert>}
                                {message && <Alert severity="info">{message}</Alert>}
                                <Grid container sx={{ pt: 1 }}>
                                    <Grid item xs>
                                        <Link to="/forgotpassword">
                                            Olvidé la contraseña
                                        </Link>
                                    </Grid>
                                    {/* <Grid item>
                                        <Link to="/signup">
                                            {"Registrarme"}
                                        </Link>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 4, mb: 2 }} />
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}