
import * as React from "react";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import configureStore from "./store";
import bg from './assets/images/background.jpg';
import App from "./App";
import { Grid, ThemeProvider } from "@mui/material";
import theme from "./theme";

const back = {
  heroContainer: {
    width: '100vw',
    height: '100vh',
    spacing: 0,
    justify: 'space-around',
    fit: 'cover',
    backgroundImage: `url(${bg})`
  }
};

const store = configureStore();

const rootElement = (document.getElementById('root') as Element);
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Grid style={back.heroContainer}>
          <App />
        </Grid>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);