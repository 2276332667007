import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../System/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../System/actions';
import { SetPasswordUser } from '../../../api/Client';
import { IResponse } from '../../../models/IResponse';
import { ILogin } from '../../../models/ILogin';
import { AxiosResponse } from 'axios';

export function* setPasswordRequestAsync(action: types.requestSetPasswordAction) {
    const response: IResponse = yield call(SetPasswordUser, action.data);
    try {
        const {
            data,
            status,
            statusText,
        } = response;
        
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* setPasswordSaga() {
    yield all([
        takeEvery(types.SETPASSWORD_REQUEST, setPasswordRequestAsync)
    ])
}