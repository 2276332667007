export const env : { [key: string]: string } = {
    name:  String(process.env.REACT_APP_name),
    NODE_ENV:  String(process.env.REACT_APP_NODE_ENV),
    BASE_URL: String(process.env.REACT_APP_BASE_URL),
    BASE_URL_SECURE: String(process.env.REACT_APP_BASE_URL_SECURE),
    URL_POWER_FINANCE: String(process.env.REACT_APP_URL_POWER_FINANCE),
    URL_EXTERNAL: String(process.env.REACT_APP_URL_EXTERNAL),
    URL_CLIENTS: String(process.env.REACT_APP_URL_CLIENTS),

    ROUTE_CONFIRM_EMAIL: String(process.env.REACT_APP_ROUTE_CONFIRM_EMAIL),

    AUTORIZATION: String(process.env.REACT_APP_AUTORIZATION),
    KEY: String(process.env.REACT_APP_KEY),
    SECRET: String(process.env.REACT_APP_SECRET),

    SATWS_API: String(process.env.REACT_APP_SATWS_API),
    SATWS_KEY: String(process.env.REACT_APP_SATWS_KEY),
    SATWS_SECRET: String(process.env.REACT_APP_SATWS_SECRET)
};