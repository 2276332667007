import * as React from 'react';
import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import Image from 'mui-image';
import logo from '../../assets/pchcapital_cuadrado.png';
import * as forgotPasswordActions from './Redux/actions';
import { useDispatch, useSelector } from "react-redux";
import Copyright from '../../components/common/CopyRight';
import { isEmpty } from 'ramda';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const inProgress = useSelector((state: any) => state.forgotPassword.inProgress);
    const message = useSelector((state: any) => state.forgotPassword.message);
    const [email, setEmail] = useState('');
    const [usingEmail, setUsingEmail] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('Email inválido — La dirección de correo electrónico no es válida!');

    const forgotPasswordRequest = useCallback(() => dispatch(forgotPasswordActions.requestForgotPassword(email)), [dispatch, email]);

    const forgotPW = (event: any) => {
        event.preventDefault();
        setShowError(false);
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // if (!usingEmail) {
        //     forgotPasswordRequest();
        //     return;
        // }
        if (reg.test(String(email).toLowerCase())) {
            forgotPasswordRequest();
        } else {
            setShowError(true);
            setError('El correo proporcionado no es valido!')
            if (isEmpty(email)) {
                setEmail('');
            }
        }
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xs={3}>
                <Grid container>
                    <CssBaseline />
                    <Container maxWidth="xs"
                        style={{ backgroundColor: '#f2f6fc', border: '1px solid black', opacity: "0.9" }}>
                        <Box
                            sx={{
                                marginTop: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }} >
                            <Image src={logo} height="60%" width="60%" style={{ margin: '50px 0 50px 0' }} />
                            <Typography component="h1" variant="h5" color="#C91217">
                                Olvidé la contraseña
                            </Typography>
                            <Box component="form" onSubmit={forgotPW} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="email"
                                    label="Correo electrónico"
                                    type="email"
                                    id="email"
                                    onChange={e => setEmail(e.target.value)} />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1 }}
                                    disabled={inProgress} >
                                    Enviar
                                </Button >
                                {showError && <Alert severity="error">{error}</Alert>}
                                <Grid container
                                    id="respuesta"
                                    alignItems="center"
                                    justifyContent="center"
                                    color="red"
                                    sx={{ mt: 1, mb: 1 }} >
                                    {message}
                                </Grid>
                            </Box>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" startIcon={<KeyboardReturnIcon />}>
                                    Regresar
                                </Button>
                            </Link>
                        </Box>
                        <Copyright sx={{ mt: 4, mb: 2 }} />
                    </Container>
                </Grid >
            </Grid>
        </Grid>
    );
}