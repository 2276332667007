import * as types from '../../../System/types'

export interface SignInState {
    id: string,
    userName: string,
    email: string,
    jwToken: string,
    nombreUsuario: string,
    token: string,
    errors: [],
    remember: boolean,
    inProgress: boolean,
    isLoggedIn: boolean,
    isVerified: boolean,
    message?: string | null
}

const signInInitialState: SignInState = {
    id: '',
    userName: '',
    email: '',
    jwToken: '',
    nombreUsuario: '',
    token: '',
    errors: [],
    remember: false,
    inProgress: false,
    isLoggedIn: false,
    isVerified: false
};

export default function signInReducer(
    state: SignInState = signInInitialState,
    action: types.RootAction
): SignInState {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                userName: action.data.userName,
                remember: action.data.remember,
                inProgress: true,
                message: ''
            }
        case types.LOGIN_RESPONSE:
            return {
                ...state,
                ...action.data,
                message: action.message,
                isLoggedIn: action.isLoggedIn,
                inProgress: false 
            }
        case types.LOGIN_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}