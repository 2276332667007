import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../System/types';
import * as R from 'ramda';
import { CredentialsUser, FindTokenLoginClient, FindValidStatus, WebHookStatus } from '../../../api/Client';
import { IWebHookStatusResponse } from '../../../models/IWebHookStatusRequest';
import { ICredentialsResponse } from '../../../models/ICredentials';
import { IFindValidStatusResponse } from '../../../models/IFindValidStatusResponse';
import { IFindTokenLoginClientResponse } from '../../../models/IFindTokenLoginClientResponse';

//#region FIND TOKEN LOGIN
export function* findTokenLoginClientRequestAsync(action: types.requestFindTokenLoginClientAction) {
    const response: IFindTokenLoginClientResponse = yield call(FindTokenLoginClient, action.data);
    // console.log(response);
    try {
        const {
            data,
            status,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* findTokenLoginClientSaga() {
    yield all([
        takeEvery(types.FINDTOKENLOGINCLIENT_REQUEST, findTokenLoginClientRequestAsync)
    ])
}
//#endregion

//#region FIND VALID STATUS
export function* findValidStatusRequestAsync(action: types.requestFindValidStatusAction) {
    const response: IFindValidStatusResponse = yield call(FindValidStatus, action.data);
    // console.log(response);
    try {
        const {
            data,
            status,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* findValidStatusSaga() {
    yield all([
        takeEvery(types.FINDVALIDSTATUS_REQUEST, findValidStatusRequestAsync)
    ])
}
//#endregion

//#region CREDENTIALS
export function* credentialsRequestAsync(action: types.requestCredentialsAction) {
    const response: ICredentialsResponse = yield call(CredentialsUser, action.data);
    // console.log(response);
    try {
        const {
            data,
            status,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* credentialsSaga() {
    yield all([
        takeEvery(types.CREDENTIALS_REQUEST, credentialsRequestAsync)
    ])
}
//#endregion

//#region WEBHOOK STATUS
export function* webHookStatusRequestAsync(action: types.requestWebHookStatusAction) {
    const response: IWebHookStatusResponse = yield call(WebHookStatus, action.data);
    // console.log(response);
    try {
        const {
            data,
            status,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* webHookStatusSaga() {
    yield all([
        takeEvery(types.WEBHOOKSTATUS_REQUEST, webHookStatusRequestAsync)
    ])
}
//#endregion