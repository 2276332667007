import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../System/types';
import * as R from 'ramda';
import { ForgotPasswordUser } from '../../../api/Client';
import { IResponse } from '../../../models/IResponse';

export function* forgotPasswordRequestAsync(action: types.requestForgotPasswordAction) {
    const response: IResponse = yield call(ForgotPasswordUser, action.email);
    try {
        const {
            data,
            status,
            statusText,
        } = response;
        
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* setPasswordSaga() {
    yield all([
        takeEvery(types.FORGOTPASSWORD_REQUEST, forgotPasswordRequestAsync)
    ])
}