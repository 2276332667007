import * as React from 'react';
import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Image from 'mui-image';
import logo from '../../assets/pchcapital_cuadrado.png';
import * as setPasswordActions from './Redux/actions';
import { useDispatch, useSelector } from "react-redux";
import Copyright from '../../components/common/CopyRight';
import { ISetPassword } from '../../models/ISetPassword';
import * as R from 'ramda';
import { ListItemText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';


export default function SetPassword() {
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();
    const inProgress = useSelector((state: any) => state.setPassword.inProgress);
    const errorsSPW = useSelector((state: any) => state.setPassword.errors);
    const messageSPW = useSelector((state: any) => state.setPassword.message);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const aplicacion = 3;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = (R.isNil(urlParams.get('code')) ? '' : urlParams.get('code') + '');
    const email = (R.isNil(urlParams.get('email')) ? '' : urlParams.get('email') + '');

    let dataSend: ISetPassword = {
        email,
        token,
        password,
        confirmPassword,
        aplicacion
    }

    const setPasswordRequest = useCallback(() => dispatch(setPasswordActions.requestSetPassword(dataSend)), [dispatch, dataSend]);

    const setPW = (event: any) => {
        event.preventDefault();
        setShowError(false);
        setPasswordRequest();
    };
    let spanError = "";
    if (!R.isNil(errorsSPW)) {
        spanError = errorsSPW.map((msg: string) =>
            <ListItemText
                key={msg} primary={msg} />)
    }
    else {
        spanError = messageSPW + "... Redireccionando.";
        setTimeout(() => {
            navigate("/");
        },
            3000);
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Grid container component="main">
                    <Container style={{ backgroundColor: '#f2f6fc', border: '1px solid black', marginTop: '50px', marginBottom: '0px', opacity: "0.9" }} >
                        <Box
                            sx={{
                                marginTop: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                            <Image src={logo} height="50%" width="50%" style={{ marginBottom: '30px' }} />
                            <Typography component="h1" variant="h5" color="#C91273">
                                Restablecer contraseña
                            </Typography>
                            <Box component="form" onSubmit={setPW} sx={{ mt: 1 }} >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    autoFocus
                                    autoComplete="current-password"
                                    onChange={e => setPassword(e.target.value)} />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmpassword"
                                    label="Confirmar contraseña"
                                    type="password"
                                    id="confirmpassword"
                                    autoComplete="current-password"
                                    onChange={e => setConfirmPassword(e.target.value)} />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1 }}
                                    disabled={inProgress} >
                                    Restablecer
                                </Button >
                                <Grid item
                                    id="respuesta"
                                    justifyContent="flex-end"
                                    color="red"
                                    sx={{ mt: 1, mb: 1 }} >
                                    {spanError}
                                </Grid>
                            </Box>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" startIcon={<KeyboardReturnIcon />}>
                                    Regresar
                                </Button>
                            </Link>
                        </Box>
                        <Copyright sx={{ mt: 2, mb: 2 }} />
                    </Container>
                </Grid>
            </Grid>
        </Grid>

    );
}