import { Link, Typography } from "@mui/material";

export default function Copyright(props: any) {
    return (
        <>
            <Typography variant="body2" color="text.secondary" align="center" {...props} >
                <Link color="inherit" href="https://pchserviciosfinancieros.com/" target="_blank">
                    PCH Servicios Financieros
                </Link>
                <Typography variant="body2" sx={{ pt: 1 }}>
                    {'© Derechos Reservados, '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Typography>
        </>
    );
}