import { ISetPassword } from '../../../models/ISetPassword';
import * as type from '../../../System/types';
import * as actions from '../../../System/types';

export function requestSetPassword(data: ISetPassword): actions.requestSetPasswordAction {
    return {
        type: type.SETPASSWORD_REQUEST,
        inProgress: true,
        data
    };
}

export function onSetPasswordResponse(
    data: any,
    message: string,
    errors: Array<string>
): actions.onSetPasswordResponseAction {
    return {
        type: type.SETPASSWORD_RESPONSE,
        data,
        message,
        errors,
        inProgress: false
    };
}

export function onSetPasswordFailed(
    errors: Array<string>,
    message: string
): actions.getSetPasswordFailedAction {
    return {
        type: type.SETPASSWORD_FAILED,
        errors,
        message,
        inProgress: false
    };
}