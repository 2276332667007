import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import Image from 'mui-image';
import logo from '../../assets/pchcapital_cuadrado.png'
import Copyright from '../../components/common/CopyRight';
import { Link } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Button, Paper } from '@mui/material';

export default function BadParameters() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xs={3}>
                <Grid container component="main">
                    <Paper elevation={24} sx={{ p: 2 }}>
                        <Box
                            sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                            <Typography component="h1" variant="h5" color="#C96B12">
                                Los parámetros son incorrectos.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" startIcon={<KeyboardReturnIcon />}>
                                    Regresar
                                </Button>
                            </Link>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}