import { Link, Outlet, RouteObject, useParams, useRoutes } from 'react-router-dom';
import SignIn from '../screens/SignIn/SignIn';
import SetPassword from '../screens/SetPassword/SetPassword';
import * as R from 'ramda';
import NotFound from '../screens/NotFound/NotFound';
import ForgotPassword from '../screens/ForgotPassword/ForgotPassword';
import { useSelector } from 'react-redux';
import Applications from '../screens/Applications/Applications';
import Credentials from '../screens/Credentials/Credentials';
import { env } from '../api/env';

export default function NavigationRoutes() {

  const isLoggedIn = useSelector((state: any) => state.signIn.isLoggedIn);

  let routes: RouteObject[];
  console.log('IIS APP RUN AT => ' + env.BASE_URL)
  if (!isLoggedIn) {
    routes = [
      {
        path: "/",
        element: <SignIn />,
        children: [
          { index: true, path: env.BASE_URL + '/applications', element: <Applications /> },
        ],
      },
      { path: env.BASE_URL + '/forgotpassword', element: <ForgotPassword /> },
      { path: env.BASE_URL + '/credentials', element: <Credentials /> },
      { path: env.BASE_URL + '/setpassword', element: <SetPassword /> },
      { path: "*", element: <NotFound /> },
    ];
  } else {
    routes = [
      {
        path: "/",
        element: <Applications />,
        children: [
          { index: true, path: env.BASE_URL + '/applications', element: <Applications /> },
        ],
      },
      { path: "*", element: <NotFound /> },
    ];
  }
  return (useRoutes(routes));
}