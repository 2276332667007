import { DatosCredentials, ICredentialsResponse } from '../../../models/ICredentials';
import { DatosFindTokenLoginClient } from '../../../models/IFindTokenLoginClientResponse';
import { DatosFindValidStatus } from '../../../models/IFindValidStatusResponse';
import { DatosWebHookStatus } from '../../../models/IWebHookStatusRequest';
import * as types from '../../../System/types'

//#region FIND TOKEN LOGIN
export interface FindTokenLoginClientState {
    errors: [],
    inProgress: boolean,
    message?: string | null,
    // rfc: string,
    // password: string,
    data: DatosFindTokenLoginClient | null,
    autorizaConsulta: boolean
}

const FindTokenLoginClientInitialState: FindTokenLoginClientState = {
    errors: [],
    inProgress: false,
    // rfc: '',
    // password: '',
    data: null,
    autorizaConsulta: false
};

export function findTokenLoginClientReducer(
    state: FindTokenLoginClientState = FindTokenLoginClientInitialState,
    action: types.RootAction
): FindTokenLoginClientState {
    switch (action.type) {
        case types.FINDTOKENLOGINCLIENT_REQUEST:
            return {
                ...state,
                inProgress: true,
                message: ''
            }
        case types.FINDTOKENLOGINCLIENT_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.FINDTOKENLOGINCLIENT_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region FIND VALID STATUS
export interface FindValidStatusState {
    errors: [],
    inProgress: boolean,
    message?: string | null,
    // rfc: string,
    // password: string,
    data: DatosFindValidStatus | null,
    autorizaConsulta: boolean
}

const findValidStatusInitialState: FindValidStatusState = {
    errors: [],
    inProgress: false,
    // rfc: '',
    // password: '',
    data: null,
    autorizaConsulta: false
};

export function findValidStatusReducer(
    state: FindValidStatusState = findValidStatusInitialState,
    action: types.RootAction
): FindValidStatusState {
    switch (action.type) {
        case types.FINDVALIDSTATUS_REQUEST:
            return {
                ...state,
                inProgress: true,
                message: ''
            }
        case types.FINDVALIDSTATUS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.FINDVALIDSTATUS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region CREDENTIALS
export interface CredentialsState {
    errors: [],
    inProgress: boolean,
    message: string | null,
    data: DatosCredentials | null
}

const credentialsInitialState: CredentialsState = {
    errors: [],
    inProgress: false,
    data: null,
    message: ''
};

export function credentialsReducer(
    state: CredentialsState = credentialsInitialState,
    action: types.RootAction
): CredentialsState {
    switch (action.type) {
        case types.CREDENTIALS_REQUEST:
            return {
                ...state,
                inProgress: true,
                message: ''
            }
        case types.CREDENTIALS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.CREDENTIALS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region WEBHOOK STATUS
export interface WebHookStatusState {
    errors: [],
    inProgress: boolean,
    message?: string | null,
    // rfc: string,
    // password: string,
    data: DatosWebHookStatus | null,
    autorizaConsulta: boolean
}

const webHookStatusInitialState: WebHookStatusState = {
    errors: [],
    inProgress: false,
    // rfc: '',
    // password: '',
    data: null,
    autorizaConsulta: false
};

export function webHookStatusReducer(
    state: WebHookStatusState = webHookStatusInitialState,
    action: types.RootAction
): WebHookStatusState {
    switch (action.type) {
        case types.WEBHOOKSTATUS_REQUEST:
            return {
                ...state,
                inProgress: true,
                message: ''
            }
        case types.WEBHOOKSTATUS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.WEBHOOKSTATUS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion