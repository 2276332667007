import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: {
            main: '#1c3648'
        },
        secondary: {
            main: '#19857b'
        },
        info: {
            main: '#16a0ff'
        },
        error: {
            main: '#B70F0F'
        },
        success: {
            main: '#079426'
        },
        warning: {
            main: '#968E8E'
        }
    }
});

export default theme;