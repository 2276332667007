import { all, fork } from 'redux-saga/effects';
import signInSaga from '../screens/SignIn/Redux/saga';
import setPasswordSaga from '../screens/SetPassword/Redux/saga';
import forgotPasswordSaga from '../screens/ForgotPassword/Redux/saga';
import { credentialsSaga, findTokenLoginClientSaga, findValidStatusSaga, webHookStatusSaga } from '../screens/Credentials/Redux/saga';

export default function* rootSagas() {
    yield all([fork(signInSaga)])
    yield all([fork(setPasswordSaga)])
    yield all([fork(forgotPasswordSaga)])
    yield all([fork(findTokenLoginClientSaga)])
    yield all([fork(findValidStatusSaga)])
    yield all([fork(credentialsSaga)])
    yield all([fork(webHookStatusSaga)])
}