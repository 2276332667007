const API = {
    ACCOUNTS: '/3/user/',
    CLIENTS: '/client/',
    SATWS: '/api/v1/Credentials/',
    WEBHOOK: '/api/v1/webHook/',
    SATWS_API: '/satws/',
    REGISTRO: '/0/registro/'
};

const RootApiConstants = {
    SAT_WS_PRODUCTION: 'https://api.satws.com/credentials',
    LOGIN: API.ACCOUNTS + 'centralized-authenticate-user',
    CONFIRM_EMAIL: API.ACCOUNTS + 'confirm-email?userId={userId}&code={code}',
    FORGOT_PASSWORD: API.ACCOUNTS + 'forgot-password',
    RESSET_PASSWORD: API.ACCOUNTS + 'reset-password',
    REGISTER: API.ACCOUNTS + 'register',
    CONSULTA_STATUS: API.WEBHOOK + 'GetStatusWebHookResponse',
    CONSULTA_FINDVALIDSTATUS: API.SATWS_API + 'getValidStatusResponse',
    CONSULTA_CIEC: API.SATWS_API + 'postCredentials',
    CONSULTA_WEBHOOKSTATUS: API.SATWS_API + 'getStatusWebHookResponse',
    CONSULTA_TOKENLOGIN: API.REGISTRO + 'obtenerTokenLoginCliente'
}

export default RootApiConstants;