import * as types from '../../../System/types'

export interface ForgotPasswordState {
    email: string,
    token: string,
    inProgress: boolean,
    message?: string | null
}

const forgotPasswordInitialState: ForgotPasswordState = {
    email: '',
    token: '',
    message: '',
    inProgress: false
};

export default function forgotPasswordReducer(
    state: ForgotPasswordState = forgotPasswordInitialState,
    action: types.RootAction
): ForgotPasswordState {
    switch (action.type) {
        case types.FORGOTPASSWORD_REQUEST:
            return {
                ...state,
                inProgress: true,
                message: ''
            }
        case types.FORGOTPASSWORD_RESPONSE:
            return {
                ...state,
                ...action.data,
                message: action.message,
                inProgress: false 
            }
        case types.FORGOTPASSWORD_FAILED:
            return {
                ...state,
                inProgress: false,
                message: action.message
            }
        default:
            return state;
    }
}