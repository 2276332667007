import Axios, { AxiosError, AxiosResponse } from 'axios';
import * as Config from './env';
import { Buffer } from 'buffer';
import { IResponse } from '../models/IResponse';
import * as R from 'ramda';
import { GetResponseErrors } from '../utils';

global.Buffer = Buffer;
const encodedToken = Buffer.from(
  `${Config.env.KEY}:${Config.env.SECRET}`,
  'utf8',
).toString('base64');

const client = Axios.create({
  baseURL: Config.env.BASE_URL_SECURE,
  withCredentials: false,
  timeout: 60000,
  headers: {
    // 'Cache-Control': 'no-cache',
    // 'x-apikey': '59a7ad19f5a9fa0808f11931',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // 'Access-Control-Allow-Credentials': 'false',
    // 'Origin': 'https://localhost:3001',
    'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json',
    // Accept: 'application/json',
  },
});

const get = async (path: string, params?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'GET',
    params: params,
  };
  return await GetData(config);
};

const postSatWS = async (
  path: string,
  data: any
): Promise<IResponse> => {

  var url = `${Config.env.URL_EXTERNAL}${path}`;
  var baseUrl = Config.env.URL_EXTERNAL;
  
  const config = {
    url: url,
    baseURL: baseUrl,
    method: 'POST',
    data: data
  };

  return await new Promise<IResponse>((resolve, reject) => {
    client.request(config)
      .then((response: AxiosResponse<IResponse>) => {
        console.log(JSON.parse(response.request.response));
        const newResponse: IResponse = response.data;
        newResponse.data = JSON.parse(response.request.response);
        newResponse.code = response?.status.toString();
        newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
        newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';
        resolve(newResponse);
      })
      .catch((resultError: AxiosError<IResponse>) => {
        const { code, message, response } = resultError;
        const auxData = response?.data;
        if (!R.isNil(auxData)) {
          const errors = GetResponseErrors(auxData);
          const newResponse: IResponse = auxData;
          if (errors.length > 0) {
            newResponse.errors = errors;
          }
          newResponse.code = response?.status.toString();
          newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
          newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';
          resolve(newResponse);
        } else if (code == 'ECONNABORTED') {
        } else {
          reject(resultError);
        }
      });
  });

};

const post = async (
  path: string,
  params?: any,
  data?: any,
  appendHeader: boolean = false,
  isSatWs: boolean = false
): Promise<IResponse> => {
  var url = `${Config.env.BASE_URL_SECURE}${path}`;
  var baseUrl = Config.env.BASE_URL_SECURE;
  if (appendHeader) {
    url = `${Config.env.URL_EXTERNAL}${path}`;
    baseUrl = Config.env.URL_EXTERNAL;
  }

  if (isSatWs) {
    client.defaults.headers.common[Config.env.SATWS_KEY] = Config.env.SATWS_SECRET;
    url = Config.env.SATWS_API;
    baseUrl = url;
  } else {
    if (!R.isNil(params)) {
      client.defaults.headers.common['pchsfRoute'] = params.route;
      client.defaults.headers.common['pchsfOrigin'] = params.origin;
    }
  }

  const config = {
    url: url,
    baseURL: baseUrl,
    method: 'POST',
    params: params,
    data: data
  };

  return await GetData(config);
};

const put = async (path: string, data?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'PUT',
    data: data,
  };
  return await GetData(config);
};

const remove = async (path: string, data?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'DELETE',
    data: data,
  };
  return await GetData(config);
};

const GetData = async (config: any): Promise<IResponse> => {
  return await new Promise<IResponse>((resolve, reject) => {
    client.request(config)
      .then((response: AxiosResponse<IResponse>) => {
        const newResponse: IResponse = response.data;
        newResponse.code = response?.status.toString();
        newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
        newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';
        resolve(newResponse);
      })
      .catch((resultError: AxiosError<IResponse>) => {
        const { code, message, response } = resultError;
        const auxData = response?.data;
        if (!R.isNil(auxData)) {
          let newResponse: IResponse;
          if (R.isEmpty(auxData)) {
            newResponse = {
              code: response?.status.toString(),
              status: R.isNil(response?.status) ? 0 : Number(response?.status.toString()),
              statusText: (R.isNil(response?.statusText) ? '' : response?.statusText) + '',
              data: null,
              errors: [],
              message: '',
              succeeded: false
            }
          } else {
            newResponse = auxData;
          }
          const errors = GetResponseErrors(auxData);
          if (errors.length > 0) {
            newResponse.errors = errors;
          }

          newResponse.code = response?.status.toString();
          newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
          newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';

          resolve(newResponse);
        } else {
          reject(resultError);
        }
      });
  });
}

export const updateAuthHeader = (token: string) => {
  client.defaults.headers.common = {
    Authorization: 'Bearer ' + token,
  };
  return token;
};

export default {
  postSatWS,
  get,
  post,
  put,
  remove,
};