import { FunctionComponent } from "react";
import NavigationRoutes from "./navigations/App";
import { BrowserRouter, Link } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Container from "@mui/material/Container/Container";
import { CssBaseline } from "@mui/material";
import theme from "./theme";

const App: FunctionComponent = props => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <NavigationRoutes />
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;