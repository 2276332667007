import { all, put, fork, select, call, takeEvery } from 'redux-saga/effects'
import { IResponse } from '../models/IResponse';
import * as loginActions from '../screens/SignIn/Redux/actions'
import * as setPasswordActions from '../screens/SetPassword/Redux/actions'
import * as forgotPasswordActions from '../screens/ForgotPassword/Redux/actions'
import * as credentialsActions from '../screens/Credentials/Redux/actions'
import * as types from '../System/types'
import { IResponseCommon } from '../models/IResponseCommon';
import { IAuthenticate } from '../models/IAuthenticate';
import * as R from 'ramda';
import { ICredentialsResponse } from '../models/ICredentials';
import { IWebHookStatusResponse } from '../models/IWebHookStatusRequest';
import { IFindValidStatusResponse } from '../models/IFindValidStatusResponse';
import { IFindTokenLoginClientResponse } from '../models/IFindTokenLoginClientResponse';
import { findValidStatusRequestAsync } from '../screens/Credentials/Redux/saga';
import { GetOToken } from '../utils';

const StatusCode = Object.freeze({
    SUCCESS: 200,
    BAD_REQUEST: 400,
    INVALID_CREDENTIALS: 404,
    BAD_GATEWAY: 500
})

let statusCode: number = 0;

export function* controlledStates(
    response: any,
    responseType: string,
    code: number,
    showLogs = true,
) {
    statusCode = code
    response && showLogs && displayLogs(response, responseType)
    switch (responseType) {
        case types.LOGIN_REQUEST:
            yield onLoginRequest(response);
            break
        case types.SETPASSWORD_REQUEST:
            yield onSetPasswordRequest(response);
            break;
        case types.FORGOTPASSWORD_REQUEST:
            yield onForgotPasswordRequest(response);
            break;
        case types.FINDTOKENLOGINCLIENT_REQUEST:
            yield onFindTokenLoginClientRequest(response);
            break;
        case types.FINDVALIDSTATUS_REQUEST:
            yield onFindValidStatusRequest(response);
            break;
        case types.CREDENTIALS_REQUEST:
            yield onCredentialsRequest(response);
            break;
        case types.WEBHOOKSTATUS_REQUEST:
            yield onWebHookStatusRequest(response);
            break;
        default:
            alert('CONTROLLER[' + responseType + '] - TYPE NOT DEFINED')
            break
    }
}

function displayLogs(response: IResponse, type: string) {
    const data = JSON.stringify(response?.data);
}

function commonResponse(response: any) {
    switch (response.status) {
        case StatusCode.SUCCESS:
            if (R.isNil(response.errors)) {
            }
            break
        case StatusCode.BAD_REQUEST:
            // response.message = 'Ocurrieron errores.'
            break
        case StatusCode.INVALID_CREDENTIALS:
            response.message = 'Credenciales de usuario incorrectas.'
            break
        case StatusCode.BAD_GATEWAY:
            response.message = 'Error al procesar la solicitud. No tienes conexión.'
            break
        default:
            response.message = 'Operación denegada, favor de inténtarlo más tarde.'
            break
    }
    return response;
}

function* onLoginRequest(response: IResponse) {
    let isLoggedIn = false;
    let userID = null
    const common: IResponseCommon = response;
    const data: IAuthenticate = response.data;
    commonResponse(common);
    if (common.status == StatusCode.SUCCESS) {
        response.data.succeeded = true;
        isLoggedIn = true;
    }
    yield put(loginActions.onLoginResponse(response.data, response.message, isLoggedIn));
}

function* onSetPasswordRequest(response: IResponse) {
    const common: IResponseCommon = response;
    commonResponse(common);

    if (common.status == StatusCode.SUCCESS) {
        response.data.succeeded = true;
        yield put(setPasswordActions.onSetPasswordResponse(response.data, response.message, common.errors));
    }
    if (common.status == StatusCode.BAD_REQUEST) {
        yield put(setPasswordActions.onSetPasswordFailed(common.errors, response.message));
    }
}

function* onForgotPasswordRequest(response: IResponse) {
    const common: IResponseCommon = response;
    commonResponse(common);

    if (common.status == StatusCode.SUCCESS) {
        response.data.succeeded = true;
        yield put(forgotPasswordActions.onForgotPasswordResponse(response.data, response.message));
    }
    if (common.status == StatusCode.BAD_REQUEST) {
        yield put(forgotPasswordActions.onForgotPasswordFailed(response.message));
    }
}

function* onFindTokenLoginClientRequest(response: IFindTokenLoginClientResponse) {
    const common: IResponseCommon = response;
    commonResponse(common);
    if (common.status == StatusCode.SUCCESS) {
        response.succeeded = true;
        let jwt = response.data.token.split('.');
        let authorization: any =  GetOToken(jwt[1]);
        localStorage.setItem('token', jwt[1]);
        localStorage.setItem('rfcToCiec', authorization.rfc);

        yield put(credentialsActions.onFindTokenLoginClientResponse(response.data, response.message));
        yield put(credentialsActions.requestFindValidStatus(authorization.rfc));
    }
    if (common.status == StatusCode.BAD_REQUEST) {
        yield put(credentialsActions.onFindTokenLoginClientFailed(response.message));
    }
}

function* onFindValidStatusRequest(response: IFindValidStatusResponse) {
    const common: IResponseCommon = response;
    commonResponse(common);

    if (common.status == StatusCode.SUCCESS) {
        response.succeeded = true;
        yield put(credentialsActions.onFindValidStatusResponse(response.data, response.message));
    }
    if (common.status == StatusCode.BAD_REQUEST) {
        yield put(credentialsActions.onFindValidStatusFailed(response.message));
    }
}

function* onCredentialsRequest(response: IResponse) {
    const common: IResponseCommon = response;
    commonResponse(common);

    if (common.status == StatusCode.SUCCESS) {
        response.succeeded = true;
        yield put(credentialsActions.onCredentialsResponse(response.data, "Enviado"));
    }
    if (common.status == StatusCode.BAD_REQUEST) {
        yield put(credentialsActions.onCredentialsFailed("Error"));
    }
}

function* onWebHookStatusRequest(response: IResponse) {
    const common: IResponseCommon = response;
    commonResponse(common);

    if (common.status == StatusCode.SUCCESS) {
        // response.succeeded = true;
        yield put(credentialsActions.onWebHookStatusResponse(response.data, response.message));
    }
    if (common.status == StatusCode.BAD_REQUEST) {
        yield put(credentialsActions.onWebHookStatusFailed(response.message));
    }
}